// WhatsAppIcon.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppIcon = () => {
  return (
    <a
      href="https://wa.me/8290126665" // Replace with your WhatsApp number
      target="_blank"
      rel="noopener noreferrer"
      style={styles.whatsappIcon}
    >
      <FontAwesomeIcon icon={faWhatsapp} size="3x" style={styles.icon} />
    </a>
  );
};

const styles = {
  whatsappIcon: {
    position: 'fixed',
    bottom: '100px',
    right: '20px',
    backgroundColor: '#25D366',
    borderRadius: '50%',
    padding: '10px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    zIndex: 1000,
  },
  icon: {
    color: 'white', // Ensure the icon color contrasts with the background
  },
};

export default WhatsAppIcon;
