import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import { Fade } from "react-awesome-reveal";

const initialState = {
  name: "",
  mobileNumber: "",
  message: "",
};

export const Contact = (props) => {

  const form = useRef();


  const [{ name, mobileNumber, message }, setState] = useState(initialState);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    
    emailjs
      .sendForm(
        'service_pjkkejf', 
        'template_64ehkej', 
        e.target,
        'GL6LfzRtNp6fhvv2w',
        )
      .then(
        (result) => {
          console.log(result.text);
          setSuccess("Message sent successfully!");
          clearState();
        },
        (error) => {
          console.error(error.text);
          setError("There was an error sending your message. Please try again.");
        }
      )
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>
              </div>
              {error && <p className="text-danger">{error}</p>}
              {success && <p className="text-success">{success}</p>}
              <form
                name="contactForm"
                onSubmit={handleSubmit}
                ref={form}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="tel"
                        id="mobileNumber"
                        name="mobileNumber"
                        className="form-control"
                        placeholder="Phone"
                        required
                        onChange={handleChange}
                        value={mobileNumber}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <Fade cascade damping={0.3} triggerOnce>
              <div className="contact-item">
                <h3>Contact Info</h3>
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>
                  <a
                    href={`tel:${props.data ? props.data.phone : ""}`}
                  >
                    {props.data ? props.data.phone : "loading"}
                  </a>
                </p>
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </Fade>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.instagram : "/"}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
